define([
    'lodash',
    'textCommon',
    'santa-components',
    'skins',
    'textArea/skins/skins.json',
    'componentsCore'
], function (_, textCommon, santaComponents, skinsPackage, skinsJson, componentsCore) {
    'use strict';

    const MIN_MARGIN = 10;
    const labelUtils = textCommon.labelUtils;

    const getTextPadding = function (textAlignment, textPadding) {
        switch (textAlignment) {
            case 'left':
                return {
                    paddingLeft: textPadding,
                    paddingRight: MIN_MARGIN
                };
            case 'right':
                return {
                    paddingLeft: MIN_MARGIN,
                    paddingRight: textPadding
                };
            case 'center':
                return {
                    paddingRight: textPadding,
                    paddingLeft: textPadding
                };
        }
    };

    const getPublicState = function (state) {
        return santaComponents.mixins.validatableMixin.getPublicState(state);
    };

    /**
     * @class components.TextArea
     * @extends {core.skinBasedComp}
     */
    const textArea = {
        displayName: 'TextArea',
        mixins: [componentsCore.mixins.skinBasedComp,
            santaComponents.mixins.runTimeCompData,
            textCommon.textScaleMixin,
            santaComponents.mixins.inputFocusMixin,
            santaComponents.mixins.validatableMixin.validatable,
            santaComponents.mixins.compStateMixin(getPublicState)],

        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            shouldResetComponent: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetComponent,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive
        },

        statics: {
            behaviors: _.assign({},
                santaComponents.mixins.inputFocusMixin.INPUT_FOCUS_BEHAVIORS,
                santaComponents.mixins.validatableMixin.VALIDATABLE_BEHAVIORS
            )
        },
        focus() {
            this.refs.textarea.focus();
        },
        blur() {
            this.refs.textarea.blur();
        },

        setCustomValidity(customValidity) {
            this.refs.textarea.setCustomValidity(customValidity);
        },

        getInitialState() {
            return _.assign(this.getCssState(this.props), getPublicState(), {
                value: this.props.compData.value
            });
        },

        getCssState(props) {
            return {
                '$validation': props.compProp.message ? 'invalid' : 'valid'
            };
        },

        componentWillReceiveProps(nextProps) {
            if (nextProps.shouldResetComponent && nextProps.shouldResetComponent !== this.props.shouldResetComponent) {
                this.hideValidityIndication();
            }

            const newState = this.getCssState(nextProps);
            if (_.has(nextProps.compData, 'value') && nextProps.compData.value !== this.state.value) {
                newState.value = nextProps.compData.value;
            }

            this.setState(newState);
        },

        onClick(event) {
            if (this.props.compProp.isPreset) {
                event.target.select();
            }
        },

        onKeyDown(event) {
            this.handleAction('keyPress', event);
            event.stopPropagation();
        },

        onInput(event) {
            this.handleAction('onInput', event);
        },

        onChange(event) {
            const newValue = event.target.value;
            if (newValue === this.state.value) {
                return;
            }
            this.setState({value: newValue}, function () {
                this.updateData({value: newValue});
            }.bind(this));
            this.latestChangeEvent = event;
        },

        onFocus(event) {
            this.handleAction('focus', event);
        },

        onBlur(event) {
            this.handleAction('blur', event);
            if (this.latestChangeEvent) {
                this.handleAction('change', this.latestChangeEvent);
                this.latestChangeEvent = null;
            }
            this.showValidityIndication();
        },
        measureComponent(node) {
            const {label} = this.refs;

            const labelMeasure = labelUtils.measureComponentWithLabel(this.props.compProp, label, this.props.compData, this.props.isMobileView);
            if (labelMeasure) {
                return [{node, type: 'css', changes: {height: labelMeasure}}];
            }
        },

        getBasteTextAreaProperties() {
            const {compProp, compData, isResponsive} = this.props;
            const baseTextAreaProperties = {
                value: this.state.value,
                maxLength: compData.maxLength || undefined,
                placeholder: compData.placeholder || compProp.placeholder,
                onChange: compProp.onChange || this.onChange,
                onClick: this.onClick,
                onKeyDown: this.onKeyDown,
                onInput: this.onInput,
                onFocus: this.onFocus,
                onBlur: compProp.onBlur || this.onBlur,
                disabled: compProp.isDisabled,
                required: compProp.required,
                readOnly: compProp.readOnly,
                tabIndex: compProp.tabIndex,
                className: 'has-custom-focus'
            };
            if (isResponsive) {
                _.assign(baseTextAreaProperties, {rows: '1'});
            }
            return baseTextAreaProperties;
        },

        getSkinProperties() {
            const {compProp, isMobileView, compData, compTheme, isResponsive} = this.props;
            const displayNone = {style: {display: 'none'}};
            const getMessage = function getMessage() {
                return {children: compProp.message, style: {'whiteSpace': 'normal'}};
            };

            const classSet = {
                'with-validation-indication': this.shouldShowValidityIndication(),
                'required': labelUtils.showRequired(compData, compProp, compTheme, isResponsive)
            };
            classSet[`${this.props.compProp.textAlignment}-direction`] = true;
            const id = {compId: this.props.id, inputId: 'textarea'};
            const skinProperties = _.merge(labelUtils.getBaseInputWithLabelSkinProperties({compProp, rawFontSyle: this.getFontSize('fntlbl'), isMobileView, compData, id}), {
                '': {
                    className: this.classSet(classSet),
                    disabled: compProp.isDisabled,
                    'data-disabled': compProp.isDisabled
                },
                textarea: this.getBasteTextAreaProperties(),
                errorMessage: compProp.message ? getMessage() : displayNone
            });

            skinProperties.textarea = _.merge({
                style: _.merge(this.getFontSize('fnt'), getTextPadding(compProp.textAlignment, compProp.textPadding))
            }, skinProperties.textarea, {
                'data-preview': _.isFunction(this.getComponentPreviewState) && this.getComponentPreviewState()
            });
            return skinProperties;
        }
    };

    componentsCore.compRegistrar
        .register('wixapps.integration.components.inputs.TextArea', textArea)
        .register('wysiwyg.viewer.components.inputs.TextAreaInput', textArea);

    skinsPackage.skinsMap.addBatch(skinsJson);

    return textArea;
});
